.contact-container-one {
    text-align: center;
    margin-bottom: 40px;
}

.contact-me {
    font-size: 2rem;
}

.contact-container {
    display: flex;
    justify-content: center;
}

.contact-container-second {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container {
    width: 100%;
}

.contact-form-group {
    margin-bottom: 1rem;
}

.contact-input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.contact-form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    outline: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.contact-form-control:focus {
    border: 1px solid #313131;
}

select.contact-form-control[size],
select.contact-form-control[multiple] {
    height: auto;
}

textarea.contact-form-control {
    font-family: -apple-system, Arial, sans-serif;
    height: auto;
}

label.contact-label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.contact-btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    margin-bottom: 50px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.contact-btn:hover {
    color: #212529;
    text-decoration: none;
}

.contact-btn-primary {
    color: #fff;
    background-color: #283618;
    border-color: #283618;
}

.contact-btn-primary:hover,
.contact-btn-primary:focus,
.contact-btn-primary.focus {
    color: #fff;
    background-color: #606C38;
    border-color: #606C38;
}

.contact-btn-lg,
.contact-btn-group-lg>.contact-btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.contact-btn-block {
    display: block;
    width: 100%;
}

.contact-btn-block+.contact-btn-block {
    margin-top: 0.5rem;
}

input[type="submit"].contact-btn-block,
input[type="reset"].contact-btn-block,
input[type="button"].contact-btn-block {
    width: 100%;
}

@media (prefers-reduced-motion: reduce) {
    .contact-btn {
        transition: none;
    }
}