.icon {
    width: 40px;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.icon2 {
    width: 44px;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 7px;
}