.about-container {
    margin: 2rem;
  }

  .about-title {
    font-size: 2rem;
    color: #333;
  }

  .about-text {
    font-size: 1.5rem;
    color: #666;
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
  }