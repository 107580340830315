.navigation {
  background: #283618;
  padding: 24px 42px 24px 42px;
}

.nav-items {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: flex-end;
}

.about,
.projects,
.resume,
.contact {
  color: white;
  font-weight: bold;
  font: sans-serif, arial;
  text-decoration:none;
}

.about:hover,
.projects:hover,
.resume:hover,
.contact:hover {
  color: #DDA15E;
}

.bar {
  height: 2px;
  width: 100%;
  background-color: #DDA15E;
}

@media (max-width: 768px) {
  .bar {
    display: none;
  }
}
